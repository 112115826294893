export default {
  path: '/materials',
  name: 'materials',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/finance/classification',
  children: [
    {
      path: 'classification',
      meta: { title: '物料分类', permission: 'classification' },
      component: () => import('@/views/materials/classification/index'),
    },
    {
      path: 'attr',
      meta: { title: '物料属性', permission: 'attr' },
      component: () => import('@/views/materials/attr/index'),
    },
    {
      path: 'unit',
      meta: { title: '物料单位', permission: 'unit' },
      component: () => import('@/views/materials/unit/index'),
    },
    {
      path: 'information',
      meta: { title: '物料信息', permission: 'information' },
      component: () => import('@/views/materials/information/index'),
    },
  ],
}